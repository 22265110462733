import React, { FC, useState } from 'react';
import styles from './ChooseProvider.module.css';
import clsx from 'clsx';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ProviderType } from '../../redux/services/provider';
import { CreateProvider } from './createPanel/CreateProvider';
import { CreateProviderByTemplate } from './createPanel/CreateProviderByTemplate';
import { BACKEND_URL } from '../../constants';
import { CustomTypography } from '../custom/CustomTypography';
import { BaseSidePanel } from '../sidePanel/BaseSidePanel';
import { CreateEthereumProvider } from './createPanel/CreateEthereumProvider';
import { AddTemplates } from '../extensions/AddTemplates';

type TCreateProvider = {
  isOpen: boolean;
  onClose: () => void;
};

export type TTemplate = {
  type: ProviderType;
  name: string;
  pathToAvatar: string;
};

export const ChooseProvider: FC<TCreateProvider> = ({ isOpen, onClose }) => {
  const ethereumProviderAvatar = 'public/images/provider/ethereum.svg';

  const [providerTemplate, setProviderTemplate] = useState<TTemplate>({
    type: ProviderType.CUSTOM,
    name: '',
    pathToAvatar: '',
  });

  const [isCreateFormTemplateOpen, setIsCreateFormTemplateOpen] = useState(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isCreateFormEthereumOpen, setIsCreateFormEthereumOpen] = useState(false);

  const providersTemplates: TTemplate[] = [
    {
      name: 'Яндекс',
      type: ProviderType.YANDEX,
      pathToAvatar: 'public/images/provider/yandex.svg',
    },
    {
      name: 'Вконтакте',
      type: ProviderType.VK,
      pathToAvatar: 'public/images/provider/vk.svg',
    },
    {
      name: 'Mail.ru',
      type: ProviderType.MAILRU,
      pathToAvatar: 'public/images/provider/mail.svg',
    },
    {
      name: 'Google',
      type: ProviderType.GOOGLE,
      pathToAvatar: 'public/images/provider/google.svg',
    },
    {
      name: 'OpenID Connect',
      type: ProviderType.CUSTOM,
      pathToAvatar: '',
    },
    {
      name: 'Ethereum',
      type: ProviderType.ETHEREUM,
      pathToAvatar: ethereumProviderAvatar,
    },
  ];

  const handleProviderClose = (
    closeProvider: (arg: false) => void,
    closeChooseProvider?: boolean,
  ) => {
    closeProvider(false);
    if (closeChooseProvider) onClose();
  };

  const handleTemplateClick = async (template: TTemplate) => {
    try {
      if (template.type === ProviderType.CUSTOM) {
        setIsCreateFormOpen(true);
      } else if (template.type === ProviderType.ETHEREUM) {
        setIsCreateFormEthereumOpen(true);
      } else if (
        template.type !== ProviderType.CREDENTIALS &&
        template.type !== ProviderType.EMAIL
      ) {
        setProviderTemplate({
          ...providerTemplate,
          type: template.type,
          name: template.name,
          pathToAvatar: template.pathToAvatar,
        });
        setIsCreateFormTemplateOpen(true);
      }
    } catch (e) {
      console.log('createProvider error', e);
    }
  };

  return (
    <>
      <BaseSidePanel
        handleClosePanel={onClose}
        isOpenPanel={isOpen}
        title={'Создать способ входа'}
        description={'Выберите шаблон способа входа'}
        isNoBackdrop
      >
        <div className={styles.wrapper}>
          <div className={styles['choose-provider']}>
            {providersTemplates.map((template) => {
              return (
                <ListItem
                  key={template.type}
                  className={styles.provider}
                  onClick={() => handleTemplateClick(template)}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + template.pathToAvatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!template.pathToAvatar && <IdIcon />}
                  </div>
                  <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                    {template.name}
                  </CustomTypography>
                </ListItem>
              );
            })}
            <AddTemplates onClose={onClose} />
          </div>
        </div>
      </BaseSidePanel>
      <CreateProviderByTemplate
        isOpen={isCreateFormTemplateOpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormTemplateOpen, closeChooseProvider);
        }}
        providerTemplate={providerTemplate}
      />
      <CreateProvider
        isOpen={isCreateFormOpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormOpen, closeChooseProvider);
        }}
      />
      <CreateEthereumProvider
        pathToAvatar={ethereumProviderAvatar}
        isOpen={isCreateFormEthereumOpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormEthereumOpen, closeChooseProvider);
        }}
      />
    </>
  );
};
