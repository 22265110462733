import React, { FC, useState } from 'react';
import styles from './ChooseProvider.module.css';
import clsx from 'clsx';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import { CustomTypography } from 'src/components/custom/CustomTypography';
import { CreateEsiaProvider } from './create/CreateEsiaProvider';
import { CreateQRCodeProvider } from './create/CreateQRCodeProvider';
import { addMethodsType } from '../Settings';
import Avatar from '@mui/material/Avatar';
import { getImageURL } from 'src/helpers';

type TProviderTemplate = {
  pathToAvatar: string;
  name: string;
  onClick?: () => void;
  type: addMethodsType;
};

interface IChooseMethodsProps {
  onClose: () => void;
}

export const ChooseMethods: FC<IChooseMethodsProps> = ({ onClose }) => {
  const qrCodeLogo = 'public/images/provider/qrcode.svg';
  const esiaLogo = 'public/images/provider/esia.svg';

  const [isFormQROpen, setIsFormQROpen] = useState(false);
  const [isFormEsiaOpen, setIsFormEsiaOpen] = useState(false);

  const providerTemplates: TProviderTemplate[] = [
    {
      name: 'QRCODE',
      type: addMethodsType.QRCODE,
      pathToAvatar: qrCodeLogo,
      onClick: () => setIsFormQROpen(true),
    },
    {
      name: 'ЕСИА',
      type: addMethodsType.ESIA,
      pathToAvatar: esiaLogo,
      onClick: () => setIsFormEsiaOpen(true),
    },
  ];

  const handleProviderClose = (
    closeProvider: (arg: false) => void,
    closeChooseProvider?: boolean,
  ) => {
    closeProvider(false);
    if (closeChooseProvider) onClose();
  };

  return (
    <>
      {providerTemplates.map((provider) => {
        return (
          <ListItem key={provider.type} className={styles.provider} onClick={provider.onClick}>
            <Avatar
              src={getImageURL(provider.pathToAvatar)}
              className={styles['provider-icon-wrapper']}
            >
              {!provider.pathToAvatar && <IdIcon />}
            </Avatar>
            <CustomTypography className={clsx('text-14', styles['provider-name'])}>
              {provider.name}
            </CustomTypography>
          </ListItem>
        );
      })}
      <CreateQRCodeProvider
        pathToAvatar={qrCodeLogo}
        isOpen={isFormQROpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsFormQROpen, closeChooseProvider);
        }}
      />
      <CreateEsiaProvider
        pathToAvatar={esiaLogo}
        isOpen={isFormEsiaOpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsFormEsiaOpen, closeChooseProvider);
        }}
      />
    </>
  );
};
