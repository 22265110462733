import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom-v5-compat';
import { CustomMuiIcon } from 'src/components/custom/CustomMuiIcon';
import { CLIENT_ID, PROJECT_NAME } from '../../../constants';
import { Roles } from '../../../enums';
import {
  exportToJson,
  formatDate,
  formatPhoneNumber,
  getClaimPrivacy,
  getImageURL,
  isOwnerOrEditor,
} from '../../../helpers';
import { ReactComponent as AvatarIcon } from '../../../icons/Avatar.svg';
import { TAppSlice } from '../../../redux/appSlice';
import { setNotice } from '../../../redux/noticesSlice';
import { useLazyDeleteAllSessionQuery } from '../../../redux/services/auth';
import { TUser, useLazyGetUsersQuery } from '../../../redux/services/client';
import { useDeleteUsersMutation } from '../../../redux/services/owner';
import { useGetProfileFieldsQuery } from '../../../redux/services/settings';
import {
  AccountTypes,
  useGetPrivateClaimsQuery,
  useGetPublicExternalAccountsQuery,
} from '../../../redux/services/user';
import { RootState } from '../../../redux/store';
import { TUserProfile, TUserSlice } from '../../../redux/userSlice';
import { CustomButton } from '../../custom/CustomButton';
import { CustomTypography } from '../../custom/CustomTypography';
import { ChangePasswordBlock } from '../../profile/ChangePasswordBlock';
import { ExternalAccount } from '../../profile/ExternalAccount';
import { EClaimPrivacy, PublicStatusPopover } from '../../profile/PublicStatusPopover';
import { UserPopover } from '../../profile/UserPopover';
import styles from './ApplicationUserInfo.module.css';
import { ChangePermissionsModal } from './ChangePermissionsModal';
import { useLazyGetUserClientQuery } from 'src/redux/services/clients';

type TApplicationUserInfoComponent = {
  isMobile: TAppSlice['isMobile'];
  userRole?: string;
  userRoleInApp: TUserSlice['userRoleInApp'];
  rightPanel?: boolean;
  userIdProp?: number;
  clientIdProp?: string;
  loggedUserId: TUserProfile['id'];
  toggleUpdateUsers: TAppSlice['toggleUpdateUsers'];
  updateUserList?: () => void;
};

const mapStateToProps = (state: RootState) => ({
  isMobile: state.app.isMobile,
  userRole: state.user.userProfile.roleRootApp,
  userRoleInApp: state.user.userRoleInApp,
  loggedUserId: state.user.userProfile.id,
  toggleUpdateUsers: state.app.toggleUpdateUsers,
});
const ApplicationUserInfoComponent: FC<TApplicationUserInfoComponent> = ({
  userRole,
  isMobile,
  userRoleInApp,
  userIdProp,
  clientIdProp,
  rightPanel,
  loggedUserId,
  toggleUpdateUsers,
  updateUserList,
}) => {
  const currentPath = useLocation();
  const getClientId = () =>
    currentPath.pathname.endsWith('/users') ? CLIENT_ID : String(clientIdProp);
  const { clientId = '', userId } = rightPanel
    ? { clientId: getClientId(), userId: userIdProp }
    : useParams<{ clientId: string; userId: string }>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: string } | null | undefined
  >(null);
  const [selectedPermission, setSelectedPermission] = useState<null | string>(null);
  const [changePermissionsModalOpen, setChangePermissionsModalOpen] = useState(false);
  const [deleteUsersModalOpen, setDeleteUsersModalOpen] = useState(false);
  const date = new Date(selectedUser?.user.birthdate || 0);
  const [getUser] = useLazyGetUserClientQuery();
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();
  const { data: externalAccounts } = useGetPublicExternalAccountsQuery(
    { user_id: String(userId), client_id: clientId },
    {
      skip: (!isOwnerOrEditor(userRole) && userRoleInApp !== Roles.EDITOR) || !userId,
    },
  );
  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userId), {
    skip: !isOwnerOrEditor(userRole) || !userId,
  });
  const {
    public_profile_claims_oauth,
    public_profile_claims_gravatar,
    public_accounts_claims_oauth,
    public_accounts_claims_gravatar,
  } = privateClaims || {};
  const { data: profileFields } = useGetProfileFieldsQuery();
  const customFields =
    profileFields?.filter((field) => field.type === 'custom' && field.active) || [];
  const dispatch = useDispatch();
  const [deleteUsers, { isLoading: deleteUsersLoading }] = useDeleteUsersMutation();
  const deleteAppsWithUser = false;
  const disableButtons = deleteUsersLoading;

  const changePasswordLink =
    userId?.toString() === loggedUserId
      ? '/profile/change-password'
      : `/applications/user/${clientId}/${userId}/change-password`;

  const closeChangePermissionModal = () => {
    setSelectedPermission(null);
    setChangePermissionsModalOpen(false);
  };

  useEffect(() => {
    const start = async () => {
      if (!userId) return;
      const { data: user } = await getUser({
        clientId: clientId || '',
        userId: String(userId),
      });

      setSelectedUser(user);
    };
    start();
  }, [clientId, userId, toggleUpdateUsers]);

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => setAnchorEl(null);
  const name = (
    (selectedUser?.user.given_name || '') +
    ' ' +
    (selectedUser?.user.family_name || '')
  ).trim();

  const clearUsers = async () => {
    setSelectedUser(null);
    updateUserList && updateUserList();
  };

  const handleDeleteButton = async () => {
    try {
      if (!selectedUser?.user?.id) return;
      setDeleteUsersModalOpen(true);
    } catch (e) {
      console.log('handleDeleteButton error', e);
    }
  };

  const handleDeleteUser = async () => {
    try {
      if (!selectedUser?.user?.id) return;

      await deleteUsers({
        delete_apps_with_user: deleteAppsWithUser,
        checked_ids: [selectedUser?.user.id],
      }).unwrap();

      const id: string =
        selectedUser.user.nickname || selectedUser.user.login || selectedUser.user.id.toString();

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Пользователь ${id} удален в ${PROJECT_NAME}.`,
          timestamp: new Date().toString(),
          avatar: selectedUser?.user.picture,
        }),
      );

      clearUsers();
      setDeleteUsersModalOpen(false);
    } catch (e) {
      console.log('handleDeleteUsers error', e);
    }
  };

  const lineCustomFields = customFields
    .filter(
      (field) => selectedUser?.user.custom_fields && selectedUser.user.custom_fields[field.field],
    )
    .map((field) => {
      return (
        <div key={field.field} className={styles['info-item']}>
          <CustomTypography className={clsx('text-14', styles['info-item-title'])} color="grey">
            {field.title}
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
            {selectedUser?.user.custom_fields && selectedUser.user.custom_fields[field.field]
              ? (selectedUser?.user?.custom_fields[field.field] as string)
              : 'Не задано'}
          </CustomTypography>
          {isOwnerOrEditor(userRole) && (
            <PublicStatusPopover
              claimPrivacy={getClaimPrivacy(
                field.field,
                public_profile_claims_oauth,
                public_profile_claims_gravatar,
              )}
              claims={field.field}
              userId={selectedUser?.user.id?.toString()}
            />
          )}
        </div>
      );
    });

  const checkRole = (role: string) => {
    switch (role) {
      case Roles.OWNER:
        return 'Владелец';
      case Roles.ADMIN:
        return 'Администратор приложений';
      case Roles.EDITOR:
        return 'Администратор';
      case Roles.USER:
        return 'Участник';
      case Roles.TRUSTED_USER:
        return 'Доверенный участник';
      default:
        return '';
    }
  };

  return (
    <div className={clsx('wrapper-scroll', { [styles['panel-wrapper']]: rightPanel })}>
      <div className={clsx('content', { [styles['panel-content']]: rightPanel })}>
        <div className={styles['panel-top']}>
          <div
            className={styles['app-icon-wrapper']}
            style={{
              backgroundImage: `url(${getImageURL(selectedUser?.user?.picture)})`,
            }}
          >
            {!selectedUser?.user.picture && (
              <div className={styles['app-icon-default']}>
                {selectedUser?.user.nickname
                  ?.split(' ')
                  .map((name: string) => name[0]?.toUpperCase())
                  .join('')}
              </div>
            )}
          </div>
          <div className={styles['name-wrapper']}>
            <CustomTypography
              className={clsx(
                'header-2-medium',
                'font-golos',

                styles['overflow-ellipsis'],
              )}
              component="div"
            >
              {!name && !selectedUser?.user?.profile_claims_privacy?.includes('nickname')
                ? 'Имя скрыто пользователем'
                : name || 'Нет имени'}
            </CustomTypography>
            <UserPopover
              open={isPopoverOpen}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{
                paper: styles.paper,
              }}
              PaperProps={{
                sx: {
                  transform: `translateX(${
                    290 - (anchorEl?.offsetWidth || 290)
                  }px) translateY(12px) !important`,
                },
              }}
              userId={parseInt(userId as string, 10)}
              clientId={clientId}
              loggedUserId={loggedUserId}
              setChangePermissionsModalOpen={setChangePermissionsModalOpen}
            />
            {!isMobile && (
              <div className={styles['user-buttons']}>{checkRole(selectedUser?.role || '')}</div>
            )}
          </div>
        </div>
        {isMobile && (
          <div className={styles['mobile-buttons']}>
            <Button
              onClick={isOwnerOrEditor(userRole) ? handleOpenPopover : () => ''}
              className={clsx('text-14', styles['popover-button'])}
              variant="custom"
              color="secondary"
              endIcon={<CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />}
            >
              {isOwnerOrEditor(selectedUser?.role) ? 'Администратор' : 'Участник'}
            </Button>
          </div>
        )}
        <div className={styles.panel}>
          <div className={styles['panel-title']}>
            <CustomTypography className={clsx('header-3', 'font-golos')}>
              Основная информация
            </CustomTypography>
            {isOwnerOrEditor(userRole) && (
              <Link
                to={`/applications/user/edit/${clientId}/${userId}`}
                style={{ marginLeft: 'auto', textDecoration: 'none' }}
                className={styles['margin-right']}
              >
                <Button variant="custom2">Изменить</Button>
              </Link>
            )}
          </div>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  ID пользователя
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                  {selectedUser?.user.id}
                </CustomTypography>
              </div>
              {isOwnerOrEditor(userRole) && (
                <PublicStatusPopover
                  claimPrivacy={EClaimPrivacy.publicGravatar}
                  claims="id"
                  disabled
                />
              )}
            </div>
            {!!(selectedUser?.user.nickname || '').trim() && (
              <div className={styles['info-item']}>
                <div className={styles['flex-wrap']}>
                  <CustomTypography
                    className={clsx('text-14', styles['info-item-title'])}
                    color="grey"
                  >
                    Публичное имя
                  </CustomTypography>
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {(selectedUser?.user.nickname || '').trim()
                      ? selectedUser?.user.nickname
                      : 'Нет имени'}
                  </CustomTypography>
                </div>
                {isOwnerOrEditor(userRole) && (
                  <PublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'nickname',
                      public_profile_claims_oauth,
                      public_profile_claims_gravatar,
                    )}
                    claims="nickname"
                    userId={String(selectedUser?.user.id)}
                  />
                )}
              </div>
            )}
            {!!selectedUser?.user.picture && (
              <div className={styles['info-item']}>
                <CustomTypography
                  className={clsx('text-14', styles['info-item-title'])}
                  color="grey"
                >
                  Фото профиля
                </CustomTypography>
                {selectedUser?.user.picture ? (
                  <div
                    style={{
                      backgroundImage: `url(${getImageURL(selectedUser?.user?.picture)})`,
                    }}
                    className={styles['user-icon-wrapper']}
                  />
                ) : (
                  <Avatar className={styles.avatar}>
                    <AvatarIcon />
                  </Avatar>
                )}
                {isOwnerOrEditor(userRole) && (
                  <PublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'picture',
                      public_profile_claims_oauth,
                      public_profile_claims_gravatar,
                    )}
                    claims="picture"
                    userId={String(selectedUser?.user.id)}
                  />
                )}
              </div>
            )}
            {!!name && (
              <div className={styles['info-item']}>
                <div className={styles['flex-wrap']}>
                  <CustomTypography
                    className={clsx('text-14', styles['info-item-title'])}
                    color="grey"
                  >
                    Имя и фамилия
                  </CustomTypography>
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {name || 'Не задано'}
                  </CustomTypography>
                </div>
                {isOwnerOrEditor(userRole) && (
                  <PublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'family_name',
                      public_profile_claims_oauth,
                      public_profile_claims_gravatar,
                    )}
                    claims="family_name given_name"
                    userId={String(selectedUser?.user.id)}
                  />
                )}
              </div>
            )}
            {!!selectedUser?.user.login && (
              <div className={styles['info-item']}>
                <div className={styles['flex-wrap']}>
                  <CustomTypography
                    className={clsx('text-14', styles['info-item-title'])}
                    color="grey"
                  >
                    Логин
                  </CustomTypography>
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {selectedUser?.user.login || 'Не задано'}
                  </CustomTypography>
                </div>
                {isOwnerOrEditor(userRole) && (
                  <PublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'login',
                      public_profile_claims_oauth,
                      public_profile_claims_gravatar,
                    )}
                    claims="login"
                    userId={String(selectedUser?.user.id)}
                  />
                )}
              </div>
            )}
            {!!selectedUser?.user.birthdate && (
              <div className={styles['info-item']}>
                <div className={styles['flex-wrap']}>
                  <CustomTypography
                    className={clsx('text-14', styles['info-item-title'])}
                    color="grey"
                  >
                    Дата рождения
                  </CustomTypography>
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {selectedUser?.user.birthdate ? formatDate(date) : 'Не задано'}
                  </CustomTypography>
                </div>
                {isOwnerOrEditor(userRole) && (
                  <PublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'birthdate',
                      public_profile_claims_oauth,
                      public_profile_claims_gravatar,
                    )}
                    claims="birthdate"
                    userId={String(selectedUser?.user.id)}
                  />
                )}
              </div>
            )}
            {!!selectedUser?.user.email && (
              <div className={styles['info-item']}>
                <div className={styles['flex-wrap']}>
                  <CustomTypography
                    className={clsx('text-14', styles['info-item-title'])}
                    color="grey"
                  >
                    Электронная почта
                  </CustomTypography>
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {selectedUser?.user.email ? selectedUser.user.email : 'Не задано'}
                  </CustomTypography>
                </div>
                <PublicStatusPopover
                  claimPrivacy={getClaimPrivacy(
                    'email',
                    public_profile_claims_oauth,
                    public_profile_claims_gravatar,
                  )}
                  claims="email"
                  userId={String(selectedUser?.user.id)}
                />
              </div>
            )}
            {!!selectedUser?.user.phone_number && (
              <div className={styles['info-item']}>
                <div className={styles['flex-wrap']}>
                  <CustomTypography
                    className={clsx('text-14', styles['info-item-title'])}
                    color="grey"
                  >
                    Номер телефона
                  </CustomTypography>
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {selectedUser?.user.phone_number
                      ? formatPhoneNumber(selectedUser.user.phone_number)
                      : 'Не задано'}
                  </CustomTypography>
                </div>
                <PublicStatusPopover
                  claimPrivacy={getClaimPrivacy(
                    'phone_number',
                    public_profile_claims_oauth,
                    public_profile_claims_gravatar,
                  )}
                  claims="phone_number"
                  userId={String(selectedUser?.user.id)}
                />
              </div>
            )}
            {lineCustomFields.length > 0 && (
              <div>
                <CustomTypography className={clsx('header-3', 'font-golos')}>
                  Дополнительная информация
                </CustomTypography>
                {lineCustomFields}
              </div>
            )}
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.justify}>
            <CustomTypography
              style={{ marginBottom: 24 }}
              className={clsx('text-17-regular', 'font-golos')}
            >
              Идентификаторы
            </CustomTypography>
          </div>
          <div className={styles.info}>
            {selectedUser?.user &&
              externalAccounts
                ?.filter((ea) => ea.type !== AccountTypes.EMAIL && ea.type !== AccountTypes.PHONE)
                .map((account) => (
                  <ExternalAccount
                    account={account}
                    userProfile={selectedUser.user}
                    public_accounts_claims_oauth={public_accounts_claims_oauth}
                    public_accounts_claims_gravatar={public_accounts_claims_gravatar}
                    clientId={clientId}
                    toggleUpdateUsers={toggleUpdateUsers}
                    key={
                      (account.sub || '') +
                      (account.issuer || '') +
                      (account.type || '') +
                      (account.email || '')
                    }
                  />
                ))}
          </div>
        </div>
        {isOwnerOrEditor(userRole) && (
          <ChangePasswordBlock
            passwordUpdateDate={new Date(selectedUser?.user.password_updated_at || '')}
            navigateTo={changePasswordLink}
          />
        )}
        {isOwnerOrEditor(userRole) && (
          <Accordion className={clsx(styles.panel, styles.accordion)}>
            <AccordionSummary
              className={styles['accorion-summary']}
              classes={{ content: styles['accorion-summary-content'] }}
              expandIcon={
                <IconButton size="large" disableRipple={false}>
                  <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
                </IconButton>
              }
            >
              <CustomTypography className={clsx('text-17-regular', 'font-golos')}>
                Другие действия
              </CustomTypography>
            </AccordionSummary>
            <AccordionDetails className={styles['accordion-details']}>
              <Button
                variant="custom2"
                className={styles['margin-bottom']}
                onClick={async () => {
                  await deleteAllSession(selectedUser?.user.id);
                }}
              >
                Выйти со всех устройств
              </Button>
              <Button
                onClick={() => exportToJson({ ...selectedUser }, 'profile.json')}
                variant="custom2"
                className={styles['margin-bottom']}
              >
                Скачать данные
              </Button>
              {selectedUser?.user.id !== 1 &&
                selectedUser?.user.id !== parseInt(loggedUserId as string, 10) && (
                  <Button
                    onClick={handleDeleteButton}
                    variant="custom2"
                    className={styles['margin-bottom']}
                  >
                    Удалить аккаунт
                  </Button>
                )}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <Modal open={changePermissionsModalOpen} onClose={closeChangePermissionModal}>
        <ChangePermissionsModal
          selectedClientId={clientId}
          selectedUserToActions={selectedUser?.user as Partial<TUser> | null}
          selectedUserRoleFromProps={selectedUser?.role}
          closeChangePermissionModal={closeChangePermissionModal}
          selectedPermission={selectedPermission}
          setSelectedPermission={setSelectedPermission}
          setChangePermissionsModalOpen={setChangePermissionsModalOpen}
        />
      </Modal>

      <Modal open={deleteUsersModalOpen} onClose={() => setDeleteUsersModalOpen(false)}>
        <div className={styles['modal-wrapper']}>
          <CustomTypography
            style={{ marginBottom: 24 }}
            className={clsx('header-2-medium', 'font-golos')}
          >
            Удалить аккаунт {PROJECT_NAME}
          </CustomTypography>
          <div>
            <CustomTypography className={clsx('text-14')}>
              Аккаунт{' '}
              {selectedUser?.user?.nickname || selectedUser?.user?.login || selectedUser?.user?.id}{' '}
              будет удален. Данные, связанные с аккаунтом, удалятся навсегда. Пользователь потеряет
              доступ к приложениям, в которых для входа использовал аккаунт {PROJECT_NAME}.
            </CustomTypography>
            <CustomTypography style={{ marginTop: 16 }} className={clsx('text-14')}>
              Вместе с аккаунтом будут удалены приложения, в которых удаляемый пользователь является
              владельцем.
            </CustomTypography>
          </div>
          <div style={{ marginTop: 32, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={disableButtons}
              onClick={() => setDeleteUsersModalOpen(false)}
              variant="custom"
              color="secondary"
            >
              Отмена
            </Button>
            <CustomButton
              loading={disableButtons}
              disabled={disableButtons}
              onClick={handleDeleteUser}
              style={{ marginLeft: 24, background: '#FC545C' }}
            >
              Удалить аккаунт
            </CustomButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const ApplicationUserInfo = connect(mapStateToProps)(ApplicationUserInfoComponent);
