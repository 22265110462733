import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { Roles } from '../../enums';
import { getObjectKeys } from '../../helpers';
import { getAccessToken } from '../../service/auth';
import { TCustomFields } from '../userSlice';

export type TSentry = {
  /**
   * DSN
   */
  dsn: string;
  /**
   * Включен ли Sentry
   */
  enabled: boolean;
  /**
   * ID пользователя, по действиям которого нужно отправлять трассировки и ошибки
   */
  user_id: string;
};

// export type TClient = {
//   name: string;
//   client_id: string;
//   grant_types: string[];
//   created_at: string;
//   avatar: string | null;
//   scopes?: string;
//   cover?: string | null;
//   client_secret: string;
//   description?: string;
//   domain: string;
//   post_logout_redirect_uris: string[];
//   request_uris: string[];
//   response_types: string[];
//   redirect_uris: string[];
//   is_visible: boolean;
//   refresh_token_ttl?: string;
//   access_token_ttl?: string;
//   registration_access_token: { iat: number; clientId: string; kind: string; jti: string };
//   widget_title: string;
//   show_avatar_in_widget: boolean;
//   hide_widget_header: boolean;
//   hide_widget_footer: boolean;
//   widget_colors: { button_color: string; font_color: string; link_color: string };
//   widget_info: string;
//   require_auth_time: boolean;
//   require_signed_request_object: boolean;
//   token_endpoint_auth_method: string;
//   introspection_endpoint_auth_method: string;
//   revocation_endpoint_auth_method: string;
//   id_token_signed_response_alg: string;
//   subject_type: string;
//   required_providers_ids: string[];
// } & Partial<TSettings>;

// export type TApplication = {
//   role?: Roles.OWNER | Roles.EDITOR;
//   client: TClient;
// };

export type TUserCount = {
  userCount: number;
  adminCount: number;
  ownerCount: number;
};

export type TUser = {
  id: number;
  sub: string;
  email: string;
  email_verified: string;
  birthdate: string;
  family_name: string;
  gender: string;
  given_name: string;
  locale: string;
  login: string;
  middle_name: string;
  name: string;
  nickname: string;
  phone_number: string;
  phone_number_verified: string;
  picture: string;
  preferred_username: string;
  profile: string;
  updated_at: Date;
  website: string;
  zoneinfo: string;
  hashed_password: string;
  blocked: boolean;
  deleted: string;
  profile_claims_privacy: string[];
  custom_fields: TCustomFields;
  password_updated_at?: string;
};

export const clientApi = createApi({
  reducerPath: 'clientApi',
  tagTypes: ['ManagedApps', 'AppsWithPermissions', 'SearchHistory', 'ClientDetails'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserCount: builder.query<
      TUserCount,
      {
        selectedAppId: string;
        search_string: string;
        search_filter?: string[];
        ignore_ids?: number[];
      }
    >({
      query: ({ selectedAppId, ...body }) => {
        return {
          url: `client/v1/users/count/${selectedAppId}`,
          method: 'POST',
          body,
        };
      },
    }),

    changeUsersRole: builder.mutation<
      void,
      {
        client_id: string;
        role: Roles.ADMIN | Roles.USER | Roles.EDITOR | Roles.OWNER;
        checked_ids?: number[];
        all?: boolean;
        unchecked_ids?: number[];
        search_string?: string;
        search_filter?: string[];
      }
    >({
      query: (body) => {
        return {
          url: `client/v1/roles/${body.client_id}`,
          method: 'PUT',
          body,
        };
      },
    }),

    getUsers: builder.query<
      { user: TUser; role: Roles; profile_claims_privacy: string[] }[],
      {
        client_id: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        last_record_user_id?: string;
        number_of_skip?: string;
        search_param_user_id?: string;
        search_filter?: string[];
      }
    >({
      query: ({ client_id, ...requestParams }) => {
        let body = '';

        getObjectKeys(requestParams).forEach(
          (paramKey) => (body += paramKey + '=' + requestParams[paramKey] + '&'),
        );

        return {
          url: `v1/clients/${client_id}/users`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded ',
          },
          body,
        };
      },
    }),
  }),
});

export type TRequestParams = {
  name: string;
  redirect_uris: string[];
  required_providers_ids?: string[];
  user_id?: string;
  post_logout_redirect_uris: string[];
  request_uris?: string[];
  response_types?: string[];
  grant_types: string[];
  avatar: File | null;
  domain: string;
  description: string;
  registration_access_token?: string;
  widget_colors?: { font_color: string; link_color: string; button_color: string };
  show_avatar_in_widget?: boolean;
  require_auth_time: boolean;
  require_signed_request_object: boolean;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  id_token_signed_response_alg: string;
  subject_type: string;
};

export const clientParamsToFormData = (requestParams: Partial<TRequestParams>) => {
  try {
    return getObjectKeys(requestParams).reduce((acc, key) => {
      if (
        key === 'redirect_uris' ||
        key === 'post_logout_redirect_uris' ||
        key === 'grant_types' ||
        key === 'request_uris' ||
        key === 'response_types'
      ) {
        requestParams[key]?.forEach((uri) => {
          acc.append(key + '[]', uri);
        });
        return acc;
      }

      if (key === 'required_providers_ids') {
        if (!requestParams[key]?.length) acc.append(key + '[]', '');
        else
          requestParams[key]?.forEach((id) => {
            acc.append(key + '[]', id);
          });
        return acc;
      }

      //TODO: refactor request widget color
      if (key === 'widget_colors') {
        if (requestParams.widget_colors) {
          getObjectKeys(requestParams.widget_colors).forEach((item) =>
            acc.append(item, requestParams.widget_colors?.[item] || ''),
          );

          return acc;
        }

        return acc;
      }

      const requestParam = requestParams[key];

      if (typeof requestParam === 'boolean' || typeof requestParam === 'number') {
        acc.append(key, String(requestParam));
        return acc;
      }

      acc.append(key, requestParam || '');
      return acc;
    }, new FormData());
  } catch (e) {
    console.log('clientParamsToFormData error: ' + (e as Error).message);
  }
};

export const { useLazyGetUsersQuery, useGetUserCountQuery, useChangeUsersRoleMutation } = clientApi;
