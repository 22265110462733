import { yupResolver } from '@hookform/resolvers/yup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CLIENT_ID } from '../../../constants';
import { isObjectEmpty } from '../../../helpers';
import {
  ProviderType,
  TEmailParams,
  TMiscProvider,
  useCreateProviderMutation,
  useUpdateProviderMutation,
} from '../../../redux/services/provider';
import { CustomTypography } from '../../custom/CustomTypography';
import { PasswordTextfield } from '../../custom/PasswordTextfield';
import { SharedFormProvider } from '../../shared/FormProvider';
import { ProviderSettingsSidePanel } from '../../sidePanel/ProviderSettingsSidePanel';
import { UploadAndDisplayImage } from '../../settings/clientSettings/UploadAndDisplayImage';
import styles from './PhoneProvider.module.css';
import { ConfirmationModal } from '../../modal/ConfirmationModal';

export type TEmailProvider = {
  id: string;
  avatar: string;
  params?: TEmailParams;
};

type TEmailProviderProps = {
  isOpen: boolean;
  close: (value?: boolean) => void;
  emailProvider?: TMiscProvider;
  // TODO: в emailProvider принимать только необходимые параметры
};

export type EmailProviderInputs = {
  root_mail: string;
  mail_hostname: string;
  mail_port: string;
  mail_password: string;
  mail_code_ttl_sec: string;
  avatar: File | null;
  path_to_avatar: string;
  is_public: boolean;
};

const schema = yup.object({
  root_mail: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес электронной почты не может превышать 255 символов')
    .email('Неверный формат адреса электронной почты'),
  mail_hostname: yup
    .string()
    .max(255, 'Адрес сервера исходящей почты не может превышать 2000 символов')
    .required('Обязательное поле'),
  mail_port: yup
    .string()
    .required('Обязательное поле')
    .min(1, 'Порт сервера исходящей почты не может быть меньше 1 символа')
    .max(5, 'Порт сервера исходящей почты не может превышать 5 символов')
    .matches(/^[^\n ]*$/, {
      message: 'Порт сервера исходящей почты не может содержать пробелы',
    }),
  mail_password: yup.string().required('Обязательное поле'),
  mail_code_ttl_sec: yup
    .string()
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Время жизни кода подтверждения не может содержать пробелы',
    }),
});

export const EmailProvider: FC<TEmailProviderProps> = ({ isOpen, close, emailProvider }) => {
  const emailLogo = 'public/images/provider/email.svg';

  const methods = useForm<EmailProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      path_to_avatar: emailLogo,
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { dirtyFields, errors },
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const closeSaveModal = () => setSaveModalOpen(false);
  const [createProvider, createResult] = useCreateProviderMutation();
  const [updateProvider, updateResult] = useUpdateProviderMutation();

  useEffect(() => {
    if (isOpen && emailProvider) {
      setFields(emailProvider);
    }
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  useEffect(() => {
    if (createResult.isSuccess || updateResult.isSuccess) close(true);
  }, [createResult, updateResult]);

  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const setFields = async (provider?: Partial<TMiscProvider>) => {
    try {
      const { avatar, params, is_public } = provider || {};
      setValue('is_public', !!is_public);
      if (params) {
        (Object.keys(params) as Array<keyof TEmailParams>).forEach((field) => {
          setValue(field, (params as TEmailParams)?.[field] || '', { shouldDirty: !provider });
        });
      }
      if (avatar) {
        setOverrideImage(avatar);
        setValue('avatar', null);
        setValue('path_to_avatar', avatar, { shouldDirty: !provider });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EmailProviderInputs> = (data) => {
    if (emailProvider) {
      updateProvider({
        body: {
          type: ProviderType.EMAIL,
          provider_id: emailProvider.id,
          ...data,
        },
        client_id: CLIENT_ID,
      });
    } else {
      createProvider({
        body: {
          type: ProviderType.EMAIL,
          name: 'Электронная почта',
          ...data,
        },
        client_id: CLIENT_ID,
      });
    }
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Настройки почты"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<EmailProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={createResult.isLoading || updateResult.isLoading}
      >
        <div className={styles['provider-form']}>
          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Основной почтовый адреc
          </CustomTypography>
          <TextField
            {...register('root_mail', {
              onChange: () => {
                if (errors.root_mail) {
                  clearErrors('root_mail');
                }
              },
            })}
            className="custom"
            error={!!errors.root_mail}
            helperText={errors.root_mail ? errors.root_mail.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Адрес почтовой службы, который также будет использоваться для рассылки писем
          </CustomTypography>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Адрес сервера исходящей почты
          </CustomTypography>
          <TextField
            {...register('mail_hostname', {
              onChange: () => {
                if (errors.mail_hostname) {
                  clearErrors('mail_hostname');
                }
              },
            })}
            className="custom"
            error={!!errors.mail_hostname}
            helperText={errors.mail_hostname ? errors.mail_hostname.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Адрес почтовой службы для рассылки писем
          </CustomTypography>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Порт сервера исходящей почты
          </CustomTypography>
          <TextField
            {...register('mail_port', {
              onChange: () => {
                if (errors.mail_port) {
                  clearErrors('mail_port');
                }
              },
            })}
            className="custom"
            error={!!errors.mail_port}
            helperText={errors.mail_port ? errors.mail_port.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Порт почтовой службы для рассылки писем
          </CustomTypography>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Пароль почты
          </CustomTypography>
          <PasswordTextfield
            {...register('mail_password', {
              required: true,
              onChange: () => {
                if (errors.mail_password) clearErrors('mail_password');
              },
            })}
            className="custom"
            error={!!errors.mail_password}
            helperText={errors.mail_password ? errors.mail_password.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Обычный пароль или пароль приложения, который создается в настройках аккаунта
            <br />
            почтового сервиса
          </CustomTypography>

          <div>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Изображение почты
            </CustomTypography>
            <UploadAndDisplayImage
              overRideImageSrc={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              pathToAvatar={emailProvider?.avatar || emailLogo}
              setAvatarValue={setAvatarValue}
              componentName="update-email-provider"
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            {errors.avatar && (
              <CustomTypography color="error" className={clsx('text-14', styles['input-error'])}>
                {errors.avatar.message}
              </CustomTypography>
            )}
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
            </CustomTypography>
          </div>

          <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
            Время жизни кода подтверждения
          </CustomTypography>
          <TextField
            {...register('mail_code_ttl_sec', {
              onChange: () => {
                if (errors.mail_port) {
                  clearErrors('mail_code_ttl_sec');
                }
              },
            })}
            className="custom"
            error={!!errors.mail_code_ttl_sec}
            helperText={errors.mail_code_ttl_sec ? errors.mail_code_ttl_sec.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Время жизни кодов подтверждения адреса электронной почты в секундах
          </CustomTypography>

          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>
              Использовать для входа по коду
            </CustomTypography>
            <Controller
              control={control}
              name="is_public"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
              )}
            />
          </div>
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Будут доступны в приложениях как способ входа с помощью одноразовых паролей
          </CustomTypography>
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
        actionButtonText="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />
    </ProviderSettingsSidePanel>
  );
};
