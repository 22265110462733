import Switch from '@mui/material/Switch';
import clsx from 'clsx';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { CLIENT_ID, PROJECT_NAME } from '../../constants';
import { RootState } from '../../redux/store';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './editPanel/EditProvider.module.css';

type TProviderFooter = {
  clientId: string;
  globalRole?: string;
};

const mapStateToProps = (state: RootState) => ({
  globalRole: state.user.userProfile.roleRootApp,
});

const ProviderFooterComponent: FC<TProviderFooter> = ({ clientId }) => {
  const { control } = useFormContext();
  return (
    <>
      {clientId === CLIENT_ID && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Публичный способ входа
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Способ входа будет доступен для добавления в пользовательские приложения
            </CustomTypography>
          </div>
          <Controller
            name="is_public"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Switch
                data-id="is-public-switch"
                classes={{ root: styles['switch-root'] }}
                disableRipple
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Требовать подтверждение паролем
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            После авторизации во внешней системе у пользователя будет запрошен пароль от{' '}
            {PROJECT_NAME}
          </CustomTypography>
        </div>
        <Controller
          name="password_required"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Switch
              classes={{ root: styles['switch-root'] }}
              disableRipple
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </div>
    </>
  );
};

export const ProviderFooter = connect(mapStateToProps)(ProviderFooterComponent);
