import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import { ReactComponent as WarningIcon } from '../../icons/Warning.svg';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import styles from './DeleteProfile.module.css';
import { RootState } from '../../redux/store';
import { connect } from 'react-redux';
import { checkAppsBeforeDelete, checkCredentials } from '../../requests/user';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { BACKEND_URL, PROJECT_NAME } from '../../constants';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import { CustomTypography } from '../custom/CustomTypography';
import { TClientWithRole } from 'src/redux/services/clients';
import { useDeleteUserMutation } from 'src/redux/services/users';
import { sendLogoutRequest } from 'src/requests/oidc';
import { setNotice } from 'src/redux/noticesSlice';

const mapStateToProps = (state: RootState) => ({
  login: state.user.userProfile.login,
  userId: state.user.userProfile.id,
});

type TDeleteProfileComponent = {
  login?: string;
  userId?: string;
};

const DeleteProfileComponent: FC<TDeleteProfileComponent> = ({ login = '', userId }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<null | string>(null);
  const [canDelete, setCanDelete] = useState<null | boolean>(null);
  const [userApplications, setUserApplications] = useState<TClientWithRole[]>([]);
  const [deleteUser] = useDeleteUserMutation();

  useEffect(() => {
    const onMount = async () => {
      if (userId) {
        const result = await checkAppsBeforeDelete(userId);
        setCanDelete(result.canDelete);
        setUserApplications(result.filteredApps);
      }
    };
    onMount();
  }, [userId]);

  const handleDelete = async () => {
    if (!password) {
      setPasswordError('Укажите пароль');
      return;
    }
    if (userId) {
      const verified = await checkCredentials(login, password);
      if (verified) {
        try {
          await deleteUser({ id: userId }).unwrap();
          sendLogoutRequest();
          setPasswordError('');
        } catch (error) {
          console.error('Error:', error);
          dispatch(
            setNotice({
              id: Math.random(),
              isRead: false,
              message: `Ошибка при удалении аккаунта: ${error}`,
              timestamp: new Date().toString(),
            }),
          );
        }
      } else if (verified === false) {
        setPasswordError('Неправильный пароль');
      }
    }
  };

  const applicationsComponent = () =>
    userApplications.map((app) => (
      <div key={app.client.client_id} className={styles.app}>
        <div
          style={{
            backgroundImage: `url(${BACKEND_URL + '/' + app.client.avatar})`,
          }}
          className={styles['app-icon']}
        >
          {!app.client.avatar && <AppIcon fill="#ced0d9" />}
        </div>
        <Link className={styles['app-link']} to={`/applications/${app.client.client_id}`}>
          {app.client.name}
        </Link>
      </div>
    ));
  return (
    <div className={styles.wrapper}>
      <div className={styles['delete-account']}>
        <CustomTypography
          style={{ marginBottom: 24 }}
          className={clsx('text-24-medium', 'font-golos')}
        >
          Удалить аккаунт {PROJECT_NAME}
        </CustomTypography>
        {canDelete ? (
          <>
            <CustomTypography
              color="secondary"
              style={{ marginBottom: 24 }}
              className={clsx('text-14')}
            >
              При удалении аккаунта связанные с ним данные будут безвозвратно удалены. Вы потеряете
              доступ к приложениям, в которых для входа использовали аккаунт {PROJECT_NAME}.
            </CustomTypography>
            {!!userApplications.length && (
              <>
                <CustomTypography className={clsx('header-3', 'font-golos')}>
                  Удаляемые данные
                </CustomTypography>
                <CustomTypography
                  color="secondary"
                  style={{ marginBottom: 24 }}
                  className={clsx('text-14')}
                >
                  Вы можете сохранить приложения, если назначите в них полномочия администратора
                  хотя бы одному пользователю.
                </CustomTypography>
                <div className={styles['apps-wrapper']} style={{ marginBottom: 24, marginTop: -8 }}>
                  {applicationsComponent()}
                </div>
                <CustomTypography
                  style={{ marginBottom: 16 }}
                  className={clsx('header-3', 'font-golos')}
                >
                  Подтверждение удаления
                </CustomTypography>
              </>
            )}
            <CustomTypography
              color="secondary"
              style={{ marginBottom: 8 }}
              className={clsx('text-14')}
            >
              Пароль для подтверждения
            </CustomTypography>
            <div style={{ marginBottom: 72 }}>
              <PasswordTextfield
                value={password}
                onChange={(e) => {
                  if (passwordError) setPasswordError(null);
                  setPassword(e.target.value);
                }}
                className="custom"
                FormHelperTextProps={{
                  className: clsx('text-14'),
                }}
                error={!!passwordError}
                helperText={passwordError ? passwordError : ''}
                fullWidth
                variant="standard"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => navigate(-1)} variant="custom" color="secondary">
                Отмена
              </Button>
              <Button
                onClick={handleDelete}
                variant="custom"
                color="error"
                style={{ marginLeft: 24 }}
              >
                Удалить
              </Button>
            </div>
          </>
        ) : (
          <div>
            {canDelete === null ? (
              <Skeleton style={{ height: 56 }} />
            ) : (
              <>
                <div className={styles.warning}>
                  <WarningIcon />
                  <CustomTypography
                    style={{ marginLeft: 8 }}
                    className={clsx('text-14')}
                    color="error"
                  >
                    Вы не можете удалить аккаунт, пока управляете приложениями.
                  </CustomTypography>
                </div>

                <CustomTypography className={clsx('text-14')} style={{ marginBottom: 16 }}>
                  Для удаления аккаунта назначьте полномочия администратора хотя бы одному
                  пользователю в приложениях:
                </CustomTypography>
                <div className={styles['apps-wrapper']} style={{ marginBottom: 84 }}>
                  {applicationsComponent()}
                </div>
                <Button
                  color="secondary"
                  style={{ marginLeft: 'auto', display: 'block' }}
                  variant="custom"
                  onClick={() => navigate('/applications?redirect=/profile/delete')}
                >
                  Перейти в «Приложения»
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const DeleteProfile = connect(mapStateToProps)(DeleteProfileComponent);
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
