import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { App } from './App';
import { CustomSnackbar, ESnackBarVariant } from './components/custom/CustomSnackbar';
import { PROJECT_NAME } from './constants';
import { ErrorBoundary } from './ErrorBoundary';
import './fonts.css';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

declare module 'notistack' {
  interface VariantOverrides {
    customSnackbar: {
      avatar?: string | null;
      snackbarVariant: ESnackBarVariant;
    };
  }
}

document.title = PROJECT_NAME;
const container = document.getElementById('root');
if (!container) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        Components={{
          customSnackbar: CustomSnackbar,
        }}
      >
        <App />
      </SnackbarProvider>
    </ErrorBoundary>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
